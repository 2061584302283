.p-footer {
    padding: 30px 0 0;

    @include media('md') {
        padding: 0;
    }

    &__title {
        margin-bottom: 30px;
    }
}
