$colors: ( 
    'primary': #B57E64, 
    'primary-darker': #9D6751,
    'text': #444, 
    'gray': #F3F3F3,
    'gray2': #E9E9E9,
    'gray-lighter': #F7F7F7,
    'gray-lighter2': #F8F8F8,
    'background': #fff, 
    'link': #B57E64, 
    'link-hover': #a05836, 
    'error': #c72727,
);
