.p-investment {
    padding: 30px 0;

    &__side-content {
        margin-top: 35px;
    }

    @include media ('md') {
        padding-top: 0;
        padding-bottom: 40px;
    }

    @include media ('lg') {
        padding-bottom: 150px;

        &__side-content {
            padding-left: 35px;
        }
    }

    @include media ('hd') {
        &__side-content {
            margin-right: -55px;
            padding-left: 95px;
        }
    }
}
