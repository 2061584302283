.c-types {
    &__title {
        margin: 0;
        @include font('subtitle', 'default');
    }

    

    &__item {
        display: flex;
        align-items: center;

        margin: 10px 0 15px;

        span {
            padding-left: 15px;

            @include font('subtitle');
        }
    }

    @include media('lg') {
        &__info {
            position: absolute;
            bottom: 20px;
            left: 0;
        }
    }
}
