.c-copywrites {
    padding: 20px 0;

    @include media('md') {
        display: flex;
        justify-content: flex-end;

        p {
            padding: 0 13px;
        }

        p + p {
            border-left: 1px solid color('text');
        }
    }

    p {
        line-height: 20px;
    }

    a {
        font-weight: 700;
    }
}
