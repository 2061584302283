.c-form {
    position: relative;
    
    margin: 1em 0;
}

.c-form__group {
    position: relative;
    margin: 0 0 1em;

    label {
        display: block;
        margin: .5em 0;
    
        text-transform: uppercase;
        @include font('tiny');
    
    }
}

.c-form__submit {
    margin: 2em 0;
}

.c-form__checkbox {
    input {
        vertical-align: middle;
    }

    span {
        @include font('small');
        font-weight: 300;
    }
}
