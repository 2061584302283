.c-cookies {
    display: flex;
    position: fixed;
    right: 15px;
    bottom: 15px;

    align-items: center;
    justify-content: space-around;

    width: 320px;

    padding: 10px;

    transition: opacity .4s;
    background: color('gray');
    color: color('text');
    opacity: 0;

    z-index: 14;

    &.is-active {
        opacity: 1;
    }

    & > div {
        &:first-of-type {
            flex-basis: 200px;

            border-right: 1px solid color('text');
        }
    }
}

.c-cookies__text {
    margin: 0;
    @include font('default');
    line-height: 1.3;

    a {
        color: color('text');
        font-weight: 700;
        @include font('default');
        text-decoration: underline;
    }
}

.c-cookies__button {
    color: color('text');
    @include font('default');

    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    i {
        padding-bottom: 1px;
        padding-left: 3px;


        font-size: 1.4em;
        line-height: 16px;
        vertical-align: middle;
    }
}
