.c-seller-steps {
    margin-top: -32px;
}

.c-seller-step {
    margin-bottom: 30px;

    &__index {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        padding-top: 3px;

        font-size: 20px;

        &::after {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            transform: rotate(45deg);
            border: 1px solid color('primary');

            content: ' ';
        }        
    }

    &__text {
        margin-top: 30px;

        text-align: center;
    }

    @include media('md') {
        margin-bottom: 0;

    }    
}
