input[type='email'],
input[type='number'],
input[type='password'],
input[type='tel'],
input[type='text'],
textarea {
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0;

    transition: box-shadow .1s;
    border: 0;
    border-bottom: 1px solid color('primary');
    background: transparent;

    @include font('default');
    line-height: 1.5;

    &.is-error {
        border-color: color('error');
    }
}

textarea {
    min-height: 100px;
}

.input-error {
    margin: .2em 0;
    color: color(error);
    @include font( 'small');
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
}
