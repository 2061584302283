.p-offer {
    position: relative;
    padding: 30px 0;

    &::after {
        position: absolute;
        top: 382px;
        left: -1000px;
        width: 3000px;

        height: 420px;

        background: color('gray');

        content: ' ';

        z-index: -1;
    }

    &__image {
        padding-bottom: 20px;
    }

    &__text {
        @include font('medium');
    }
    
    @include media('md') {
        padding: 75px 0 0;

        &__image {
            padding-bottom: 0;
        }
    }

    @include media('lg') {
        // 
        &::after {
            top: 152px;
        }

        &__image {
            position: absolute;
            top: 180px;
            right: 15px;
        }
    }
}
