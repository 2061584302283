.c-link {
    display: inline-block;
    position: relative;
    padding-left: 110px;
    font-weight: 700;

    &::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 90px;
        height: 1px;
        background: color('primary');
        content: ' ';
    }
}
