.p-nav {
    position: relative;
    padding-left: 25px;
    z-index: 999;

    &__hamburger {
        display: none;
        position: absolute;
        top: 30px;
        right: 0;
    }

    &__top {
        position: fixed;
        width: calc(100% - 30px);
    }

    &__logo {
        display: flex;
        position: absolute;
        top: 0;

        align-items: center;
        height: 75px;

        img {
            max-width: 210px;
            transition: max-width .3s ease-in-out;
        }
    }

    &__right {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        align-items: center;
        justify-content: flex-end;
        height: 75px;

        & > * {
            margin-left: 15px;
        }
    }

    &__menu {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
    }

    &__contact {
        position: fixed;
        bottom: 10px;
    }

    &.is-scroll {
        .p-nav__logo {
            img {
                max-width: 210px;
            }
        }
    }

    @include media ('lg') {
        &__top {
            width: calc(100% - 80px);
            max-width: 1440px;
        }

        &__right {
            height: 145px;
        }

        &__logo {
            height: 145px;

            img {
                max-width: 374px;
            }
        }

        &__contact {
            bottom: 40px;
        }
    }

    &__right--mobile {
        display: none;
    }

    @include media ('xl') {
        &__menu {
            display: block;
        }
    }

    @include media ('hd') {
        padding: 0;

        &__top {
            position: fixed;
            width: calc(100% - 30px);
        }
    }

    @include media-max ('lg') {
        &__mobile-content {
            position: fixed;
            top: -130%;
            left: 0;
            width: 100%;
            height: 100vh;
            padding: 0;

            transition: top .4s;

            background: color('gray');

            overflow: hidden;
        }

        &__hamburger {
            display: block;
        }

        &__top {
            top: 0;
            left: 15px;

            z-index: 10;
        }

        &__contact,
        &__logo,
        &__menu {
            position: absolute;
        }

        &__contact,
        &__menu {
            left: 15px;
        }

        &__right--mobile {
            display: flex;

            top: auto;
            right: 15px;
            bottom: 0;
        }

        &__right--desktop {
            display: none;
        }

        &.is-active {
            .p-nav__mobile-content {
                top: 0;
            }
        }
    }
}
