.c-about-icons {
    position: relative;
    margin-bottom: 100px;
    padding: 60px 5px;

    & > * {
        position: relative;
        z-index: 1;
    }

    &::before {
        position: absolute;

        top: 0;
        left: -15px;
        width: calc(100% + 30px);
        height: 100%;

        background: color('text');
        content: ' ';
    }

    &__item {
        margin-bottom: 25px;
        color: color('background');
        text-align: center;
    }

    &__title {
        max-width: 230px;
        margin-top: 10px;
        margin-right: auto;
        margin-left: auto;
        @include font('subtitle', 'default');
    }

    &__image {
        height: 70px;
    }

    @include media ('md') {
        margin-top: -40px;

        &::before {
            left: 0;
            width: calc(100% + 130px);
        }

        &__item {
            margin-bottom: 0;
        }
    }

    @include media ('lg') {
        padding: 70px 68px 40px 95px;
    }
}
