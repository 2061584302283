.c-contact {
    &__title {
        margin: 0;
        font-weight: 300;
        text-transform: uppercase;
    }

    &__items {
        margin: 0;
    }

    &__item {
        @include font('subtitle');

        span {
            display: inline-block;
            width: 30px;
            color: color('link');
            font-size: 16px;
        }
    }

    &__phone {
        color: color('text');
    }

    &__mail {
        font-weight: 700;
    }
}
