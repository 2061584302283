.c-offers {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    max-width: 624px;
    margin-top: 60px;

    z-index: 2;

    &__item {
        flex-basis: 100%;
        margin: 0 auto;
    }

    @include media ('md') {
        &__item {
            flex-basis: 50%;
        }
    }
}

.c-offer {
    display: block;

    max-width: 312px;
    height: 312px;

    transition: .3s background;
    background: color('gray-lighter') center center no-repeat;

    &--1 {
        background-image: url('../assets/offer-logo-1.png');

        &:active,
        &:focus,
        &:hover {
            background-image: url('../assets/offer-logo-1-white.png');
        }
    }

    @for $i from 1 through 4 {

        &--#{$i} {
            background-image: url(../assets/offer-logo-#{$i}.png);
    
            &:active,
            &:focus,
            &:hover {
                background-image: url(../assets/offer-logo-#{$i}-white.png);
            }
        }
    }



    &:active,
    &:focus,
    &:hover {
        background-color: color('primary');

        &--1 {
            background-image: url('../assets/offer-logo-1-white.png');
        }
    }
}
