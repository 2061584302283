.c-investment-steps {
    position: relative;
    margin: 0;
    padding: 40px 15px 25px;

    color: color('background');

    &::before {
        position: absolute;

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: color('text');
        content: ' ';

        z-index: -1;
    }

    &__title {
        @include font('subtitle', 'default');
        margin-top: 0;
    }

    @include media('lg') {
        padding: 40px 0 25px;

        &::before {
            left: -100px;
            width: calc(100% + 130px);
            height: 100%;
        }
    }
}

.c-investment-step {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &__index {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        margin: 10px 30px 10px 0;
        @include font('subtitle');

        &::after {
            position: absolute;
            top: -1px;
            left: -1px;

            width: 100%;
            height: 100%;

            transform: rotate(45deg);
            border: 1px solid color('primary');

            content: ' ';
        }        
    }
}
