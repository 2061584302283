html {
    overflow-x: hidden;
}

body {
    background: color('background');

    color: color(text);
    @include font('default', 'default');

    line-height: 1.4;

    overflow: hidden;
}
