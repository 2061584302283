.c-socials {
    display: flex;

    & > li {
        margin: 0 3px;
    }

    &__item {
        display: inline-block;

        width: 23px;
        height: 23px;
        
        transition: border-color .1s ease-in-out, background .1s ease-in-out;
        border: 1px solid color('primary');
        border-radius: 50%;

        background: color('background');

        text-align: center;


        span {
            margin-top: 1px;
            transition: color .1s ease-in-out;
            font-size: 14px;
            vertical-align: middle;
        }

        &:active,
        &:focus,
        &:hover {
            border-color: color('text');
            background: color('text');

            span {
                color: color('background');
            }
        }
    }
}
