.c-map {
    height: 320px;

    margin-right: -15px;
    margin-left: -15px;

    z-index: 0;

    @include media ('lg') {
        height: 520px;
        margin-top: -310px;
        margin-right: -130px;
        margin-left: 0;
    }

    .gm-fullscreen-control,
    .gmnoprint {
        display: none;
    }

    .gm-bundled-control {
        display: block;
        left: 0;

        & > * {
            display: block;
        }
    }
}
