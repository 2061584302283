$font-sizes: ( 
    'title': (xs: 30px, xl: 40px), 
    'title-span': (xs: 40px, xl: 50px), 
    'subtitle': (xs: 18px, xl: 20px), 
    'uppermedium': 18px, 
    'medium': 16px, 
    'default': 14px, 
    'small': .75rem,
    'tiny': 12px
);

$fonts: ( 
    'default': 'Fira Sans',
    'heading': 'Athelas'
);
