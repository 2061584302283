.c-about-article {
    position: relative;

    z-index: 2;

    &__content {
        padding: 20px 0 30px;
    }

    &__text {
        padding-right: 15px;
        line-height: 1.7;
    }

    &__signature {
        position: relative;
        padding-left: 110px;
        font-weight: 700;

        &::after {
            position: absolute;
            top: 50%;
            left: 0;
            width: 90px;
            height: 1px;

            background: color('gray');

            content: ' ';
        }
    }

    &__box {
        position: relative;

        & > * {
            z-index: 1;
        }

        &::before {
            position: absolute;

            top: 0;
            left: -15px;
            width: calc(100% + 30px);
            height: 100%;
            background: color('primary');

            content: ' ';
        }
    }

    &__image {
        position: relative;
    }

    &__box-inner {
        position: relative;
        padding: 0 20px 25px;
        color: color('background');

        p {
            @include font('subtitle');
        }

        a {
            display: inline-block;
            position: relative;
            padding-left: 110px;
            color: color('background');
            font-weight: 700;

            &::after {
                position: absolute;
                top: 50%;
                left: 0;
                width: 90px;
                height: 1px;

                background: color('background');

                content: ' ';
            }
        }
    }

    @include media('md') {
        &__content {
            padding: 90px 0 60px;
        }
    }

    @include media ('lg') {
        margin-top: -30px;

        &__image {
            padding: 30px 10px 20px;
        }

        &__box-inner {
            position: absolute;
            right: 68px;
            bottom: 98px;

            width: 270px;
            padding: 0;
        }

        &__box {
            &::before {
                left: 0;
                width: calc(100% + 130px);
            }
        }
    }

    @include media ('bhd') {
        margin-top: -80px;

    }
}
