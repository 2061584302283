.c-footer-box {
    &__title {
        @include font('subtitle', 'default');
        margin-bottom: 5px;
    }

    &__contacts {
        li {
            display: flex;
            align-items: flex-start;

        }

        &-icon {
            width: 24px;
            color: color('primary');
            font-size: 18px;
            text-align: center;
        }

        &-text {
            margin-bottom: 5px;
            padding-left: 15px;
            line-height: 1.7;

            &--margin {
                margin-bottom: 20px;
            }

            a {
                font-weight: 700;
            }
        }
    }

    &__text {
        max-width: 480px;

        line-height: 1.4;

        span {
            border-bottom: 1px solid color('primary');
        }
    }
}
