.c-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding: 0;

    li {
        padding: 2px 0;
    }
}

.c-menu__item {
    display: inline-block;
    position: relative;
    
    font-weight: 300;
    @include font('medium');
    
    overflow: hidden;

    &::after {
        position: absolute;
        top: 50%;
        left: -100%;

        width: 100%;
        height: 1px;

        transition: left .3s ease-in-out;
        background: color('primary');

        content: ' ';
    }

    &:active,
    &:focus,
    &:hover,
    &.is-active {
        color: color('primary');

        &::after {
            left: 0;
        }
    }

}
