//sass-lint:disable indentation property-sort-order
@font-face {
    font-family: 'Athelas';
    src: url('../assets/fonts/Athelas-Italic.woff2') format('woff2'),
        url('../assets/fonts/Athelas-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Athelas';
    src: url('../assets/fonts/Athelas-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Athelas-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Athelas';
    src: url('../assets/fonts/Athelas-Bold.woff2') format('woff2'),
        url('../assets/fonts/Athelas-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Athelas';
    src: url('../assets/fonts/Athelas-Regular.woff2') format('woff2'),
        url('../assets/fonts/Athelas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

