
@import '@raa/style-base';
@import 'settings/__settings';

@import 'vendors/__vendors';

@import 'objects/__objects';
@import 'elements/__elements';
@import 'components/__components';
@import 'patterns/__patterns';

@import 'utilities/__utilities';


* {
    outline: none;
    box-sizing: border-box;
}

@include raa-style-base($breakpoints);

@include define-colors($colors);

//define your fonts
@include define-fonts($fonts);
@include define-font-sizes($font-sizes);

//create container and grid
@include create-container('o-container', $container-sizes, 0);
@include create-grid('o-grid', 12, 30px);
