.p-main {
    padding-right: 15px;
    padding-left: 15px;

    opacity: 0;

    &.is-loaded {
        opacity: 1;
        transition: opacity .4s;
    }

    @include media ('lg') {
        width: 100%;
        max-width: 1485px;
        margin: 0 auto;

        .o-container {
            margin-right: 0;
        }
    }
}
