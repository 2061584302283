.c-about-intro {
    &__box {
        position: relative;
        padding-top: 15px;
    }

    &__text {
        margin-top: 0;
        margin-bottom: 10px;
    }

    @include media('lg') {
        &__image {
            margin-right: -65px;
        }

        &__box {
            padding-top: 35px;
        }

        &__text {
            margin-top: 0;
            margin-bottom: 35px;
        }
    }

    @include media('bhd') {
        &__box {
            padding-top: 65px;
        }
    }
}


