.c-footer-form {
    position: relative;
    padding: 35px 0 0 0;

    & > * {
        position: relative;
        z-index: 1;
    }

    &::before {
        position: absolute;

        top: 0;
        left: -15px;
        width: calc(100% + 30px);
        height: 100%;

        background: color('gray');
        content: ' ';

        z-index: 1;
    }

    &__title {
        @include font('subtitle', 'default');
        margin: 0;
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin: 15px 0;
        text-align: right;
    }

    &__button {
        padding: 15px 5px;
        border: 0;
        background: transparent;
        color: color('primary');
        font-weight: 700;
        box-shadow: none;

        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
            color: darken(color('primary'), 10);
        }
    }

    

    @include media('lg') {
        padding: 85px 0 0 50px;

        &::before {
            left: 0;
            width: calc(100% + 130px);
            height: 100%;
        }

        &__image {
            margin-left: -50px;
            margin-right: -130px;
    
            img {
                display: block;
                width: 100%;
            }
        }
    }
}
