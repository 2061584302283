.k-anim {
    display: block;
    transform: translateY(20px);
    transition: opacity .8s ease-in-out, transform .8s ease;
    opacity: 0;

    &.is-active {
        transform: translateY(0);

        opacity: 1;
    }
}

.k-image-anim {
    position: relative;
    overflow: hidden;

    &.is-white {
        &::after {
            background: color('background');
        }
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;

        width: 100%;
        height: 100%;

        transform: translateX(100%);

        transition: transform .8s ease-in-out;
        background: color('primary');

        content: ' ';
    }

    & > * {
        transition: transform .8s ease-in-out;
        opacity: 0;
    }

    &.is-active {
        &::after {
            animation: imageAnim .8s cubic-bezier(.81,.15,.26,.99);
        }

        & > * {
            animation: imageOpacityAnim .8s ease;
            animation-fill-mode: forwards;
        }
    }
}
@keyframes imageAnim {
    0% {
        transform: translateX(100%);
    }

    50% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}
@keyframes imageOpacityAnim {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }

    50% {
        transform: translateX(-20px);
        opacity: 0;

    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
