.p-seller {
    position: relative;
    padding: 30px 0;

    &::after {
        position: absolute;
        bottom: -75px;
        left: -1000px;
        width: 3000px;

        height: 510px;

        background: color('gray-lighter');

        content: ' ';

        z-index: -1;
    }


    @include media('md') {
        padding-top: 0;
        padding-bottom: 160px;
    }
}

.p-seller__box {
    &-content {
        position: relative;
        z-index: 1;
    }

    &-text {
        line-height: 1.7;
    }

    @include media ('lg') {
        display: flex;
        align-items: flex-end;

        &-content {
            margin-right: -60px;
            padding-bottom: 110px;
        }

        &-image {
            flex-basis: 416px;
            flex-shrink: 0;
        }
    }

    @include media ('hd') {
        &-image {
            flex-basis: 506px;
        }
    }
}
