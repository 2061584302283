.p-header {
    position: relative;
    padding: 105px 0 35px;

    z-index: 2;

    .o-container {
        position: relative;
    }

    &__title {
        margin: 0;
        transform: translateY(-50%);
        @include font('title');
        line-height: 1.2;
        text-transform: uppercase;

        span {
            @include font( 'title-span');
        }
    }

    &__background {
        img {
            width: 100%;
        }
    }

    @include media ('md') {
        padding: 145px 0 95px;

        &__title {
            position: absolute;

            top: 50%;
            left: 15px;

            padding-bottom: 40px;

            transform: translateY(-50%);
        }

        &__background {
            margin-right: -50px;
            padding-left: 100px;

            img {
                width: 100%;
            }
        }
    }

}
