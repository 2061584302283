.c-reviews {

}

.c-review {
    position: relative;
    
    padding-top: 10px;

    &::before {
        position: absolute;

        top: 0;
        left: -25px;
        width: 56px;
        height: 47px;

        background: url('../assets/icon-quote.png') center center no-repeat;

        content: ' ';
    }

    &__text {
        position: relative;

        max-width: 290px;
        min-height: 120px;
        line-height: 1.7;
        z-index: 1;
    }

    &__signature {
        position: relative;
        max-width: 290px;
        margin-top: 0;
        padding-left: 110px;
        font-weight: 700;

        &::after {
            position: absolute;
            top: 9px;
            left: 0;
            width: 90px;
            height: 1px;

            background: color('gray');

            content: ' ';
        }
    }
}
