.p-about {
    position: relative;
    padding: 30px 0;

    z-index: 1;

    &::after {
        position: absolute;
        top: -140px;
        left: -1000px;
        width: 3000px;

        height: 300px;

        background: color('gray-lighter');

        content: ' ';

        z-index: -1;
    }

    @include media('md') {
        padding: 50px 0 30px;
    }

    @include media('lg') {
        padding: 135px 0 80px;

        &::after {
            top: -340px;
            height: 600px;
        }
    }
}
