.c-buyer-offers {
    position: relative;
    padding-top: 20px;

    &::before {
        position: absolute;

        top: 0;
        left: -100px;
        width: calc(100% + 230px);
        height: 100%;

        background: color('gray');
        content: ' ';

        z-index: -1;
    }

    @include media ('md') {
        padding-top: 0;
        padding-bottom: 100px;

        &::before {
            top: -100px;
            left: -100px;
            width: calc(100% + 230px);
            height: calc(100% + 130px);
        }
    }
}

.c-buyer-offer {
    margin: 15px 0 30px;
    text-align: center;
}
