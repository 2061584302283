.c-site-scrollbar {
    position: relative;
    width: 1px;
    height: 80px;

    margin: 40px 0 10px;

    background: color('gray2');

    &__step {
        position: absolute;
        top: 0;
        left: 0;

        width: 1px;
        height: 0;

        background: color('primary');

    }
}
