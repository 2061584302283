.c-buyer-box {
    &__image {
        padding-bottom: 20px;
    }

    &__content {
        position: relative;
    }

    @include media ('md') {
        &__image {
            padding-bottom: 0;
        }
    }

    @include media ('lg') {
        &__image {
            margin-right: -65px;
        }

        &__content {
            padding-top: 15px;
            padding-bottom: 120px;
        }

        &__title {
            margin-bottom: 30px;
        }

        &__text {
            margin-top: 0;
            margin-bottom: 20px;
        }

        &__types {
            padding-left: 95px;
        }
    }

}
