.c-investment-box {
    &__image {
        img {
            display: block;
        }
    }

    @include media('lg') {
        &__content {
            position: relative;
            margin-right: -125px;
            padding-bottom: 45px;

            z-index: 1;
        }

        &__image {
            margin-right: -130px;

            img {
                width: 100%;
            }
        }
    }
}
